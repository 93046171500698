export const getAzEl = function(lat, lng, satLng) {
	var toDeg = 180.0 / Math.PI;
	var toRad = Math.PI / 180.0;

	var dlngr = (lng - satLng) * toRad;
	var az = Math.atan2(Math.sin(lat * toRad), Math.tan(dlngr)) * toDeg;
	az = (270.0 - az) / 360.0;
	az = az - Math.floor(az);
	az *= 360.0;
	var r1 = 6.6107; // ratio synchronous orbit/earth radius
	var clng = Math.cos(dlngr);
	var clat = Math.cos(lat * toRad);
	var v1 = r1 * clat * clng - 1.0;
	var v2 = r1 * Math.sqrt(1 - clat * clat * clng * clng);
	var el = Math.atan2(v1, v2) * toDeg;
	az = Math.round(az * 100) / 100;
	el = Math.round(el * 100) / 100;

	return { azimuth: az, elevation: el };
};

export const formatTime = function(seconds) {
	if (seconds >= 3600) {
		return Math.round(seconds / 3600 * 10) / 10 + ' hours';
	}
	if (seconds >= 60) {
		return Math.round(seconds / 60 * 10) / 10 + ' minutes';
	}
	return seconds + ' seconds';
};

export const KMG = function(val) {
	if (val > 1000000000000) {
		return Math.round(val / 1000000000) / 1000 + ' T';
	}
	if (val > 1000000000) {
		return Math.round(val / 100000000) / 10 + ' G';
	}
	if (val > 1000000) {
		return Math.round(val / 100000) / 10 + ' M';
	}
	if (val > 1000) {
		return Math.round(val / 100) / 10 + ' k';
	}
	return val;
};

export const KMG2 = function(val) {
	if (val > 1000000000000) {
		return Math.round((val / 1024 / 1024 / 1024 / 1024) * 1000) / 1000 + 'T';
	}
	if (val > 1000000000) {
		return Math.round((val / 1024 / 1024 / 1024) * 100) / 100 + 'G';
	}
	if (val > 1000000) {
		return Math.round((val / 1024 / 1024) * 100) / 100 + 'M';
	}
	if (val > 1000) {
		return Math.round((val / 1024) * 100) / 100 + 'k';
	}
	return val;
};

export const findWithAttr = function(array, attr, value) {
	for (var i = 0; i < array.length; i += 1) {
		if (array[i][attr] === value) {
			return i;
		}
	}
};

export const convertHex = function(hex, opacity) {
	hex = hex.replace('#', '');
	var r = parseInt(hex.substring(0, 2), 16);
	var g = parseInt(hex.substring(2, 4), 16);
	var b = parseInt(hex.substring(4, 6), 16);

	var result = 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
	return result;
};

export const createNetmaskAddr = function(bitCount) {
	var mask = [];
	for (var iz = 0; iz < 4; iz++) {
		var n = Math.min(bitCount, 8);
		mask.push(256 - Math.pow(2, 8 - n));
		bitCount -= n;
	}
	return mask.join('.');
};

// export const mask2cider = function(subnetMask) {
// 	var maskParts = subnetMask.split('.');
// 	var numberOfBits = maskParts
// 		//For each part calculate the number of ones
// 		.map(function(maskPart) {
// 			var numberOfBits = parseInt(maskPart, 10)
// 				.toString(2)
// 				.replace('0', '').length;
// 			return parseInt(numberOfBits, 10);
// 		})
// 		//count the number of ones in the whole array
// 		.reduce(function(previous, current) {
// 			return previous + current;
// 		});
// 	return numberOfBits;
// };
const countCharOccurences = (string , char) => string.split(char).length - 1;

const decimalToBinary = (dec) => (dec >>> 0).toString(2);
const getNetMaskParts = (nmask) => nmask.split('.').map(Number);
export const mask2cider = (netmask) => 
   countCharOccurences(
     getNetMaskParts(netmask)
      .map(part => decimalToBinary(part))
      .join(''),
    '1'   
  );    

export const MODCODS = [
	{
		name: 'QPSK1/4',
		speceff: 0.35,
		SNR: -1.7,
	},
	{
		name: 'QPSK1/3',
		speceff: 0.61,
		SNR: -0.7,
	},
	{
		name: 'QPSK2/5',
		speceff: 0.74,
		SNR: 0.1,
	},
	{
		name: 'QPSK1/2',
		speceff: 0.83,
		SNR: 1,
	},
	{
		name: 'QPSK3/5',
		speceff: 1.13,
		SNR: 2.5,
	},
	{
		name: 'QPSK2/3',
		speceff: 1.26,
		SNR: 3.3,
	},
	{
		name: 'QPSK3/4',
		speceff: 1.39,
		SNR: 4.3,
	},
	{
		name: 'QPSK4/5',
		speceff: 1.47,
		SNR: 4.9,
	},
	{
		name: 'QPSK5/6',
		speceff: 1.56,
		SNR: 5.5,
	},
	{
		name: 'QPSK8/9',
		speceff: 1.69,
		SNR: 6.6,
	},
	{},
	{
		name: '8PSK3/5',
		speceff: 1.69,
		SNR: 6.3,
	},
	{
		name: '8PSK2/3',
		speceff: 1.88,
		SNR: 7.4,
	},
	{
		name: '8PSK3/4',
		speceff: 2.07,
		SNR: 8.5,
	},
	{
		name: '8PSK5/6',
		speceff: 2.33,
		SNR: 9.6,
	},
	{
		name: '8PSK8/9',
		speceff: 2.52,
		SNR: 10.8,
	},
	{},
	{
		name: '16-APSK2/3',
		speceff: 2.5,
		SNR: 9.8,
	},
	{
		name: '16-APSK3/4',
		speceff: 2.75,
		SNR: 11.2,
	},
	{
		name: '16-APSK4/5',
		speceff: 2.92,
		SNR: 11.9,
	},
	{
		name: '16-APSK5/6',
		speceff: 3.1,
		SNR: 12.8,
	},
	{
		name: '16-APSK8/9',
		speceff: 3.35,
		SNR: 13.8,
	},
];

export const downloadCSV = function(csv, filename) {
	let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
	let url = URL.createObjectURL(blob);
	let link = document.createElement('a');
	link.setAttribute('href', url);
	link.setAttribute('download', filename);
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default { getAzEl, KMG, KMG2, findWithAttr, convertHex, createNetmaskAddr, mask2cider, MODCODS, downloadCSV };
